/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var heroBgLoad = function () {

    // Show BG once loaded to prevent flicker
    var hero = $('.hero-bg .bg');

    if (hero.length) {

      var bg = hero.css('backgroundImage'),
        src = bg.replace(/(^url\()|(\)$|[\"\'])/g, '');

      $('<img/>').attr('src', src).on('load', function () {
        hero.addClass('loaded');
      });

    }

  }
  // -------------------

  var navigation = function () {

    var open = $('.open-menu'),
        nav = $('.navigation'),
        contact = $('.contact'),
        header = $('header.primary');

    open.on('click', function() {

      nav.toggleClass('active');
      contact.toggleClass('active');
      $(this).toggleClass('active');

    });

    $('.top', header).on('click', function() {

      $('html, body').animate({
        scrollTop: $('body').offset().top-header.height()
      }, 1000);

    });

    // Scroll To
    var menuItem = $('.primary-main a:not(.open-menu)');

    menuItem.each(function() {

      $(this).on('click', function() {

        if (window.matchMedia("(max-width: 1200px)").matches) {

          nav.removeClass('active');
          contact.removeClass('active');
          open.removeClass('active');

        }

        var section = $(this).data('scroll'),
            sections = $('section');

        sections.each(function() {

          if( section == $(this).attr('id') ) {

            $('html, body').animate({
              scrollTop: $(this).offset().top-header.height() - open.height() - 40
            }, 1000);

          }

        });

      });

    });

  }
  // -------------------

  var sectionPieVideo = function () {

    var videoEmbedContainer = $('.embed-container');

    if( videoEmbedContainer.length ) {

      // Video
      var content = $('.embed-content', videoEmbedContainer),
          play = $('.embed-video-play', videoEmbedContainer),
          background = $('.embed-wrap', videoEmbedContainer),
          video = $('video', videoEmbedContainer);

      $('a', play).on('click', function() {

        // Fade Out overlays
        content.addClass('fade-out');
        play.addClass('fade-out');
        background.addClass('fade-out');

        // Fade in Video & Play
        video.delay(800).fadeIn();
        setTimeout(function() {
          video[0].play();
          video.addClass('playing');
        }, 600);        

      });

      // Overlay Content
      var overlayContent = $('.overlay-content');

      if( overlayContent.length ) {

        var overlayBanner = $('.overlay-banner a');

        overlayBanner.on('click', function() {

          // Fade Out overlays
          content.addClass('fade-out');
          play.addClass('fade-out');
          overlayContent.toggleClass('active');

          if( video.hasClass('playing') ) {
            video[0].pause();
          }

          // Fade back in if content isnt active
          if( !overlayContent.hasClass('active') ) {

            content.removeClass('fade-out');
            play.removeClass('fade-out');

            if( video.hasClass('playing') ) {
              video[0].play();
            }

          }

        });

      } // overlay content

    } // video

  }
  // -------------------

  var accordion = function () {

    var item = $('.accordion-item'),
        title = $('.item-title', item);

      title.on('click', function() {

        // Remove active class and slide up all others
        item.each(function() {
          $(this).find('.item-content').slideUp();
          $(this).removeClass('active');
        });

        // Add active class and slide down
        $(this).next('.item-content').slideToggle(function() {
            // Scroll to once open
            $('html, body').animate({
              scrollTop: $(this).parent('.accordion-item').offset().top-150
            }, 600);
        });

        $(this).parent('.accordion-item').toggleClass('active');

      });

  }
  // -------------------

  var contactFormBasic = function () {

    var contactForm = $('.contact-form-basic');

    if( contactForm.length ) {

      var checkboxList = $('.list-checkbox', contactForm),
          first = $('li:first', checkboxList),
          input = $('#time-contact');

      // Set first one
      input.val(first.text());
      first.addClass('active');

      $('li', checkboxList).on('click touchstart touchend', function() {

        // Remove active class off previous
        $('li', checkboxList).each(function() {
          $(this).removeClass('active');
        });

        $(this).toggleClass('active');

        // Get text and put into input.
        var text = $(this).find('p').text();

        input.val('');
        input.val(text);

      });

    }

  }
  // -------------------

  var openModeller = function () {
 
    var open = $('.open-modeller'),
        modeller = $('.section-modeller'),
        form = $('.form-modeller'),
        cta = $('.contact-cta', form);

    open.on('click', function() {

      $(this).fadeOut(200);
      modeller.addClass('active');
      form.delay(200).slideDown(500, function() {
        cta.addClass('active');
      });

    });

  }
  // -------------------

  var modellerEffectiveDate = function () {

    var form = $('.form-modeller'),
        dateInputs = $('.input-date');

    // Format Date of Birth
    var formatInputDob = new Cleave($('.input-date.dob'), {
        date: true,
        datePattern: ['d', 'm', 'Y'],
        delimiters: [' / ']
    });

    // Format Offer date
    var formatInputOffer = new Cleave($('.input-date.offerdate'), {
        date: true,
        datePattern: ['d', 'm', 'Y'],
        delimiters: [' / ']
    });

    // Get year from date input
    function getYears() {

      // Get Date of Birth Full Year
      var dobDate = new Date(formatInputDob.getISOFormatDate()),
          dobYear = dobDate.getFullYear();

      // Get Offer Date Full Year
      var offerDate = new Date(formatInputOffer.getISOFormatDate()),
          offerYear = offerDate.getFullYear();

      // Get year with moment
      var momentDob = moment(dobDate);
      var momentOffer = moment(offerDate);
      var momentDiff = momentOffer.diff(momentDob, 'years');
      
      // Get Age
      var yourAge = momentDiff;

      // Change text to age
      if( !isNaN(yourAge) ) {

        var effectiveDate = $('.contact-cta .tel .age', form);

        effectiveDate.text(yourAge);
        
      }

      // Add Year to data-attr for later
      $('.input-date.offerdate').attr('data-offeryear', offerYear);

    }

    // Date of Birth Input
    $('.input-date.dob').on('input', function() {

      setTimeout(function() {

        // Calculate years and change text
        getYears();

      }, 1000);

    });

    // Date of Birth Input
    $('.input-date.offerdate').on('input', function() {

      setTimeout(function() {

        // Calculate years and change text
        getYears();      

      }, 1000);

    });   

  }
  // -------------------

  var modellerInflationGrid = function() {

    var table = $('.future-inflation-grid'),
        row = $('.grid-row:not(.row-header)', table);

    var form = $('.form-modeller form');

    // CALC
    var ageEffective = parseFloat($('.form-modeller .contact-cta .tel .age').text());
    var max = ageEffective + 66;

    var range = _.range(ageEffective, max);

    // Uplifted (Exchangeable Pension + PIE Uplift)
    var exchangeablePension = parseFloat($('.exchangepension', form).val()),
        pieUplift = parseFloat($('.pieuplift', form).val());

    var epPu = exchangeablePension + pieUplift; // Exchangeable Pension + PIE Uplift

    row.each(function() {

      var inflation = $(this).data('inflation-amount');
      var thisRow = $(this);

      var previous = 0;

      var currentExchange = exchangeablePension;
      var currentCumulativeA = epPu;
      var currentCumulativeB = currentExchange;

      var crossoverReached = false;
      var crossoverAge = 0;
      var breakevenReached = false;
      var breakevenAge = 0;

      $.each(range, function(index, val) { 

          // Crossover
          var crossover = currentExchange - epPu;

          // Current Exchange with Inflation
          currentExchange += currentExchange * (inflation / 100);

          // Breakeven
          var breakeven = currentCumulativeB - currentCumulativeA;

          // Cumaltive Row 1
          currentCumulativeA += epPu;
          // Cumaltive Row 2
          currentCumulativeB += currentExchange;
       
          if( breakeven >= 0 ) {
            breakevenReached = true;
            breakevenAge = val;
          } 
          if( crossover >= 0 &&  crossoverAge == 0) {
            crossoverReached = true;
            crossoverAge = val;
          }

          if(crossoverReached == true && breakevenReached == true){
            thisRow.children('.crossover').find('span').text(crossoverAge + ' years old');
            thisRow.children('.breakeven').find('span').text(breakevenAge + ' years old');
            return false;
          } else if( crossoverReached == true && breakevenReached == false ) {
            thisRow.children('.crossover').find('span').text(crossoverAge + ' years old');
            thisRow.children('.breakeven').find('span').text('Over 65 years time');

          } else if( crossoverReached == false && breakevenReached == false ) {
            thisRow.children('.crossover').find('span').text('Over 65 years time');
            thisRow.children('.breakeven').find('span').text('Over 65 years time');
          }

          previous = val;

      });

    });

  }

  var modellerCalc = function () {

    var form = $('.form-modeller form');

    // CALC
    var ageEffective = parseFloat($('.form-modeller .contact-cta .tel .age').text());
    var max = ageEffective + 66;

    var range = _.range(ageEffective, max);

    // Uplifted (Exchangeable Pension + PIE Uplift)
    var exchangeablePension = parseFloat($('.exchangepension', form).val()),
        pieUplift = parseFloat($('.pieuplift', form).val());

    // Inflation
    var inflation = $('input.inflation').val();

    var epPu = exchangeablePension + pieUplift; // Exchangeable Pension + PIE Uplift

    // calc
    var inflationResult = Math.floor((inflation / 100) * exchangeablePension);
  
    var previous = 0;

    var currentExchange = exchangeablePension;
    var currentCumulativeA = epPu;
    var currentCumulativeB = currentExchange;

    var crossoverReached = false;
    var crossoverAge = 0;
    var breakevenReached = false;
    var breakevenAge = 0;

    var resultBox = $('.form-result-box');

    var crossoverArray = [];
    var breakevenArray = [];

    $.each(range, function(index, val) { 

        // Test
        //console.log('index: '+val+' Previous: '+previous+' Pension: '+ currentExchange +' Comm A: '+currentCumulativeA+' Comm B: '+currentCumulativeB+' ');
        
        // Crossover
        var crossover = currentExchange - epPu;

        // Current Exchange with Inflation
        currentExchange += currentExchange * (inflation / 100);

        // Breakeven
        var breakeven = currentCumulativeB - currentCumulativeA;

        // Cumaltive Row 1
        currentCumulativeA += epPu;
        // Cumaltive Row 2
        currentCumulativeB += currentExchange;

     
        if( breakeven >= 0 ) {
          breakevenReached = true;
          breakevenAge = val;
        } 
        if( crossover >= 0 &&  crossoverAge == 0) {
          crossoverReached = true;
          crossoverAge = val;
        }

        if(crossoverReached == true && breakevenReached == true){
          $('.crossoverage .year', resultBox).text(crossoverAge + ' years old');
          $('.breakevenage .year', resultBox).text(breakevenAge + ' years old');
          return false;
        } else if( crossoverReached == true && breakevenReached == false ) {
          $('.crossoverage .year', resultBox).text(crossoverAge + ' years old');
          $('.breakevenage .year', resultBox).text('Over 65 years time');

        } else if( crossoverReached == false && breakevenReached == false ) {
          $('.crossoverage .year', resultBox).text('Over 65 years time');
          $('.breakevenage .year', resultBox).text('Over 65 years time');
        }

        // Crossover Array for Google Charts
        crossoverArray[0] = ['Age', 'Increasing Pension @ '+inflation+'%', 'Uplifted PIE Pension'];
        crossoverArray[1] = [ageEffective, exchangeablePension,  epPu];
        crossoverArray[index + 2] = [val + 1, currentExchange,  epPu];

        // Breakeven Array for Google Charts
        breakevenArray[0] = ['Age', 'Increasing Pension @ '+inflation+'%', 'Uplifted PIE Pension'];
        breakevenArray[1] = [ageEffective, exchangeablePension,  epPu];
        breakevenArray[index + 2] = [val + 1, currentCumulativeB,  currentCumulativeA];

        previous = val;

    });

    // Google Chart
    google.charts.load('current', {'packages':['corechart', 'controls']});
    google.charts.setOnLoadCallback(drawChartCrossover);
    google.charts.setOnLoadCallback(drawChartBreakeven);

    var crossoverArrayLength = crossoverArray.length / 2;
    var breakevenArrayLength = breakevenArray.length / 2;

    function drawChartCrossover() {
      var data = google.visualization.arrayToDataTable(crossoverArray);

      var options = {
        curveType: 'function',
        legend: { position: 'top', alignment: 'center' },
        series: {
          0: { color: '#E4032E' },
          1: { color: '#2E2E2F' },
        },
        hAxis: {
          title: 'Age',
          gridlines: { color: '#fff', count: crossoverArrayLength },
          format: 'short',
        },
        vAxis: {
          title: 'Your Exchangeable Pension',
          gridlines: { color: '#e3e3e3' },
          format: 'short'
        },
      };

      var chart = new google.visualization.LineChart(document.getElementById('chart_crossover'));


      chart.draw(data, options);
    }

    function drawChartBreakeven() {
      var data = google.visualization.arrayToDataTable(breakevenArray);

      var options = {
        curveType: 'function',
        legend: { position: 'top', alignment: 'center', },
        series: {
          0: { color: '#E4032E' },
          1: { color: '#2E2E2F' },
        },
        hAxis: {
          title: 'Age',
          gridlines: { color: '#fff', count: breakevenArrayLength },
          format: 'short',
        },
        vAxis: {
          title: 'Your Exchangeable Pension',
          gridlines: { color: '#E3E3E3' }
        }
      };

      var chart = new google.visualization.LineChart(document.getElementById('chart_breakeven'));
      google.visualization.events.addListener(chart, 'ready', afterDraw);

      chart.draw(data, options);
    }

    function afterDraw() {

      var breakevenChart = $('.graph-breakeven.graph');

      if( !$('.graph-tabs li a#breakeven').hasClass('current') ) {

        breakevenChart.hide();

      }

    }

    $('.modeller-submit-btn').on('click', function() {
      setTimeout(function() {
        drawChartBreakeven();
        drawChartCrossover();
      }, 500);
    });

    $(window).on('resize', _.debounce(function() {
      drawChartBreakeven();
      drawChartCrossover();
    }, 100));

    var list = $('.graph-tabs');

    $('#breakeven', list).on('click', function(){

      drawChartBreakeven();

    });

    $('#crossover', list).on('click', function(){

      drawChartCrossover();

    });

  }
 // -------------------

  var modellerInflationDupe = function () {
    var inflationDupe = $('input.inflation-dupe'),
        inflation = $('input.inflation'),
        loading = $('.inflation-loading');



  inflationDupe.on('input', function() {
    inflation.val(inflationDupe.val());
    loading.css('display', 'inline-block');

    if(inflationDupe.val() >= $('.is-inflation').data('inflation')) {
      document.querySelector('input.inflation-dupe').nextElementSibling.style.display = 'inline-block';
    } else {
      document.querySelector('input.inflation-dupe').nextElementSibling.style.display = 'none';
    }

    //Wait 4 seconds
    setTimeout(function() {
      modellerCalc();
      loading.css('display', 'none');
    }, 3000);
  });

  }

  var modellerAjax = function() {

    // Get Values from form
    var ageEffective = parseFloat($('.form-modeller .contact-cta .tel .age').text());
    var offerYear = $('.input-date.offerdate').data('offeryear');

    var currentAge = ageEffective;
    var selectedYear = offerYear;
    var gender = $('input#gender').val();

    var data = {
        currentAge: currentAge,
        selectedYear: selectedYear,
        gender: gender,
    };

    $.ajax({
        method: 'GET',
        // Here we supply the endpoint url, as opposed to the action in the data object with the admin-ajax method
        url: SiteParameters.api_url + 'years/?currentAge='+currentAge+'&selectedYear='+selectedYear+'&gender='+gender, 
        data: data,
        beforeSend: function ( xhr ) {
            // Here we set a header 'X-WP-Nonce' with the nonce as opposed to the nonce in the data object with admin-ajax
            xhr.setRequestHeader( 'X-WP-Nonce', SiteParameters.api_nonce );
        },
        success : function( response ) {
          //console.log(response.expectancy);
          $('.lifeyears .year').text(response.expectancy);
          $('.lifeexpectancy .year').text(response.expectancy + ageEffective);
        },
        fail : function( response ) {
          //console.log(response.message);
        }
    });

  }


  var modellerSubmit = function () {

    var form = $('.form-modeller form'),
        submit = $('button', form);


    var checkboxList = $('.list-checkbox.gender'),
        first = $('li:first', checkboxList),
        input = $('#gender');

    // Set first one
    input.val(first.text().toLowerCase());
    first.addClass('active');

    // Clear Inputs
    $('input', form).on('focus blur', function(e) {
      var close = $(this).nextAll('.input-close');
      if( e.type == 'focus' ) {
        close.addClass('active');
      } else {
        close.removeClass('active');
      }
      
    });
    $('.input-close').on('click', function() {
      $(this).removeClass('active');
      $(this).prevAll('input').val('');
    });

    $('li', checkboxList).on('click touchstart touchend', function() {

      // Remove active class off previous
      $('li', checkboxList).each(function() {
        $(this).removeClass('active');
      });

      $(this).toggleClass('active');

      // Get text and put into input.
      var text = $(this).find('p').text().toLowerCase();

      input.val('');
      input.val(text);

    });

    // Close form, open results, scroll to
    var modeller = $('.section-modeller'),
        formModeller = $('.form-modeller'),
        formResults = $('.form-results'),
        amend = $('.amend-modeller'),
        content = $('.section-content p:not(.sub-title)', modeller),
        recommendedInflation = $('.is-inflation').data('inflation'),
        cta = $('.contact-cta', formModeller),
        ctaResults = $('.contact-cta', formResults);

    form.submit(function() {

      return false;

    }).validate({
      onkeyup: function(el, e) {
                    
          if( $('input.inflation').val() >= recommendedInflation ) {
            $('.inflation-max-msg').show();
          } else {
            $('.inflation-max-msg').hide();
          }
          
      },
      submitHandler: function(form){
        // Scroll to once open
        formModeller.fadeOut(500);
        formResults.delay(100).slideDown(500, function() {
          ctaResults.addClass('active');
          cta.removeClass('active');
        });
        amend.addClass('active');
        modeller.addClass('results-active');

        $('html, body').animate({
          scrollTop: $('.section-modeller').offset().top-150
        }, 600);

        content.fadeOut();

        // Copy over inflation number
        var inflationDupe = $('.inflation-dupe');
        inflationDupe.val($('.inflation', form).val());

        // Calculate Forms/graphs
        setTimeout(function() {
          modellerCalc();
        }, 800);
        modellerAjax();
        modellerInflationDupe();
        modellerInflationGrid();
      },
      errorElement: 'span',
      rules: {
        dob: {
          required: true,
          minlength: 14
        },
        offerdate: {
          required: true,
          minlength: 14
        },
        exchangepension: {
          required: true,
          number: true,
          minlength: 1
        },
        pieuplift: {
          required: true,
          number: true,
          minlength: 1
        },
        inflation: {
          required: true,
          number: true,
          minlength: 1,
          max: 100
        },
      },
      messages: {
        dob: 'Please enter a valid date.',
        offerdate: 'Please enter a valid date.'
      },
      errorPlacement: function(error, element) {
           error.insertAfter(element.parent('.input-wrap'));
        },
    });

    amend.on('click', function() {

      $(this).removeClass('active');
      formResults.fadeOut(500);
      modeller.removeClass('results-active');
      formModeller.delay(250).slideDown(function() {
        cta.addClass('active');
        ctaResults.removeClass('active');
      });

    });

  }
  // -------------------

  var modellerValidation = function () {

    var recommendedInflation = $('.is-inflation').data('inflation');

    $('.form-modeller form').validate({
      onkeyup: function(el, e) {
                    
          if( $('input.inflation').val() >= recommendedInflation ) {
            $('.inflation-max-msg').show();
          } else {
            $('.inflation-max-msg').hide();
          }
          
      },
      errorElement: 'span',
      rules: {
        dob: {
          required: true,
        },
        offerdate: {
          required: true,
        },
        exchangepension: {
          required: true,
          number: true
        },
        pieuplift: {
          required: true,
          number: true,
          minlength: 1
        },
        inflation: {
          required: true,
          number: true,
          max: 100
        },

      },
    });

  }
  // -------------------

  var modellerGraph = function () {

    var list = $('.graph-tabs');

    $('#breakeven', list).on('click', function(){

      $(this).addClass('current');
      $('#crossover').removeClass('current');

      $('.graph-crossover.graph').hide();
      $('.graph-breakeven.graph').show();

    });

    $('#crossover', list).on('click', function(){

      $(this).addClass('current');
      $('#breakeven').removeClass('current');

      $('.graph-breakeven.graph').hide();
      $('.graph-crossover.graph').show();

    });

  }
  // -------------------

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        heroBgLoad();
        navigation();
        sectionPieVideo();
        accordion();
        contactFormBasic();

        openModeller();

        modellerEffectiveDate();
        modellerSubmit();
        modellerGraph();

      },  
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
